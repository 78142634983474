<template>
  <BaseCard
    actions-visible
    :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
    :caption-visible="false"
    :footer-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase"> jobs </span>
    </template>
    <template #actions>
      <b-link :to="{ name: 'createJob' }">
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
        >
          + Añadir job
        </b-button>
      </b-link>
    </template>
    <JobsTable />
  </BaseCard>
</template>

<script>
import JobsTable from '@/components/jobs/table/JobsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, JobsTable },
}
</script>

<style scoped>
</style>
